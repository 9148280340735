<template>
  <main class="analysis-settings">
    <PageTitle
      title="追蹤工具設定"
      icon="chevron_left"
      hideBtn
      @iconClick="$router.push({ name: 'EcommerceParameterSetting' })"
    />

    <section class="card-container container-padding">
      <SectionTitle title="Facebook" @edit="showFBPixelDialog = true" />
      <BaseElForm>
        <BaseElFormItem label="Facebook Pixel (像素）">
          {{ fbPixelConfig || '尚未填寫' }}
        </BaseElFormItem>
      </BaseElForm>
    </section>
    <section class="card-container container-padding">
      <SectionTitle title="Google Analytics" @edit="showGADialog = true" />
      <BaseElForm>
        <BaseElFormItem label="追蹤代碼">
          {{ gaConfig || '尚未填寫' }}
        </BaseElFormItem>
      </BaseElForm>
    </section>

    <FBPixelSettingDialog
      v-if="showFBPixelDialog"
      :config="config"
      @close="showFBPixelDialog = false"
      @refresh="getConfig"
    />
    <GASettingDialog
      v-if="showGADialog"
      :config="config"
      @close="showGADialog = false"
      @refresh="getConfig"
    />
  </main>
</template>

<script>
import { GetConfig } from '@/api/ecommerce/paramter'
import FBPixelSettingDialog from './FBPixelSettingDialog.vue'
import GASettingDialog from './GASettingDialog.vue'
import { mapGetters } from 'vuex'
import { get } from 'lodash'

export default {
  name: 'AnalysisSettings',
  components: { FBPixelSettingDialog, GASettingDialog },
  data: () => ({
    config: {},
    showFBPixelDialog: false,
    showGADialog: false,
  }),
  computed: {
    ...mapGetters([
      'shop',
    ]),
    fbPixelConfig () {
      return get(this.config, 'fbPixelCode', '')
    },
    gaConfig () {
      return get(this.config, 'googleAnalyticsMeasurementId', '')
    },
  },
  async mounted () {
    await this.getConfig()
  },
  methods: {
    async getConfig () {
      const [res, error] = await GetConfig({
        shopId: this.shop,
      })
      if (error) return this.$message.warning(error)
      this.config = res
    },
  },
}
</script>

<style lang="postcss" scoped>
.container-padding {
  padding: 20px !important;
}
</style>
